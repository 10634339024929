import React, { useState } from "react";
import "../styles/Navbar.css";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import Lottie from "lottie-react";
import donate from "./../assets/RP2y3LxJAa.json";
import ModelDonet from "./ModelDonet";
const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const [showModal, setShowModal] = useState(false);
  const [donet, setdonet] = useState(false);

  return (
    <>
      <nav className="navbar navbar-1 ">
        <div className="logotransform">
          <div className="navbar-logo">
            <img src="/Assets/kitulogo.png" alt="KITU" />
            <span>KARNATAKA STATE IT/ITeS EMPLOYEES UNION</span>
          </div>
        </div>
        <div className="nav-button">
          <Modal show={showModal} onClose={() => setShowModal(false)} />
          <ModelDonet show={donet} onClose={() => setdonet(false)} />

          <button onClick={() => setShowModal(true)}>Join Us</button>
          <button onClick={() => setdonet(true)}>
            <Lottie animationData={donate} style={{ width: "100px" }} />
          </button>
        </div>
        <ul className={`navbar-menu ${isMobileMenuOpen ? "open" : ""}`}>
          <li>
            <Link to="/" style={{ color: "white" }}>
              HOME
            </Link>
          </li>
          <li>
            <Link to="/about"  style={{ color: "white" }}>ABOUT US</Link>
          </li>
          <li>
            <Link to="/event"  style={{ color: "white" }}>ACTION</Link>
          </li>
          <li>
            <Link to="/communityoutreach"  style={{ color: "white" }}>COMMUNITY OUTREACH</Link>
          </li>
          <li>
            <Link to="/studyandresearchcenter"  style={{ color: "white" }}>STUDY AND RESEARCH CENTER</Link>
          </li>
          <li>
            <Link to="/gallery"  style={{ color: "white" }}>GALLERY</Link>
          </li>
          <li>
            <Link to="/contact"  style={{ color: "white" }}>CONTACT US</Link>
          </li>
        </ul>
        {/* <div className="navbar-icons">
                    <i className="fas fa-user"></i>
                    <i className="fas fa-search"></i>
                </div> */}
        <div className="navbar-toggle" onClick={toggleMobileMenu}>
          <i className={`fas ${isMobileMenuOpen ? "fa-times" : "fa-bars"}`}></i>
        </div>
      </nav>
      {/* <div className={`offcanvas ${isMobileMenuOpen ? 'open' : ''}`}>
                <ul className="offcanvas-menu">
                    <li><a href="#home" onClick={toggleMobileMenu}>HOME</a></li>
                    <li><a href="#about" onClick={toggleMobileMenu}>ABOUT US</a></li>
                    <li><a href="#category" onClick={toggleMobileMenu}>EVENT</a></li>
                    <li><a href="#blog" onClick={toggleMobileMenu}>GALLERY</a></li>
                    <li><a href="#contact" onClick={toggleMobileMenu}>CONTACT US</a></li>
                </ul>
            </div> */}
    </>
  );
};

export default Navbar;
