import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function StudyandResearchcenter() {
  const navigate = new useNavigate();
  const [NewData, setNewData] = useState([]);
  const GetStudyResearchCenter = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getstudyresearchcenter`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetStudyResearchCenter();
  }, []);

  return (
    <div>
      <div className="gallery">
        {" "}
        <h1 style={{borderBottom:"3px solid white"}}>Study Research Center</h1>
        <Breadcrumb className="myBreadcrumb">
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item href="/studyandresearchcenter">
      Study Research Center
      </Breadcrumb.Item>
  
    </Breadcrumb>
      </div>
      <div className="container">
        <section>
          <div className="row">
            {NewData?.map((item, i) => {
              return(
                <div className="col-md-4">
                <div className="event-card">
                  <div className="all-event">
                    <div className="event-card-content">
                      <h5>{item?.title?.slice(0,40)}</h5>
                    </div>
                    <div className="event-card-content-hover">
                      <h5>{item?.title?.slice(0,40)}</h5>
                      <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item?.description?.length > 150
                                      ? item?.description.slice(0, 150) + "..."
                                      : item?.description,
                                }}
                              />
                      <a   href="/studyandresearchdetails"
                                onClick={() =>
                                  navigate("/studyandresearchdetails", {
                                    state: { item },
                                  })
                                }>
                        <button className="event-butt">Know More</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              )            
            })}

            {/* <div className="col-md-4">
              <div className="event-card">
                <div className="all-event">
                  <div className="event-card-content">
                    <h5>Stop illegal mass retrenchments in Virtusa : KITU</h5>
                  </div>
                  <div className="event-card-content-hover">
                    <h5>Stop illegal mass retrenchments in Virtusa : KITU</h5>
                    <p>
                      Massive retrenchments happening in Virtusa, in which
                      hundreds of employees made forcefully resign from the
                      company. Karnataka State IT/ITeS Employees Union (KITU)
                      strongly condemns this illegal action...
                    </p>
                    <a href="/detailsnews">
                      <button className="event-butt">Know More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="event-card">
                <div className="all-event">
                  <div className="event-card-content">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                  </div>
                  <div className="event-card-content-hover">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                    <p>
                      Massive retrenchments happening in Altisource Bangalore,
                      in which hundreds of employees terminated even without a
                      prior notice. Karnataka State IT/ITeS Employees Union
                      #KITU strongly condemns this...
                    </p>
                    <a href="/detailsnews">
                      <button className="event-butt">Know More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="event-card">
                <div className="all-event">
                  <div className="event-card-content">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                  </div>
                  <div className="event-card-content-hover">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                    <p>
                      Massive retrenchments happening in Altisource Bangalore,
                      in which hundreds of employees terminated even without a
                      prior notice. Karnataka State IT/ITeS Employees Union
                      #KITU strongly condemns this...
                    </p>
                    <a href="/detailsnews">
                      <button className="event-butt">Know More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="event-card">
                <div className="all-event">
                  <div className="event-card-content">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                  </div>
                  <div className="event-card-content-hover">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                    <p>
                      Massive retrenchments happening in Altisource Bangalore,
                      in which hundreds of employees terminated even without a
                      prior notice. Karnataka State IT/ITeS Employees Union
                      #KITU strongly condemns this...
                    </p>
                    <a href="/detailsnews">
                      <button className="event-butt">Know More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="event-card">
                <div className="all-event">
                  <div className="event-card-content">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                  </div>
                  <div className="event-card-content-hover">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                    <p>
                      Massive retrenchments happening in Altisource Bangalore,
                      in which hundreds of employees terminated even without a
                      prior notice. Karnataka State IT/ITeS Employees Union
                      #KITU strongly condemns this...
                    </p>
                    <a href="/detailsnews">
                      <button className="event-butt">Know More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="event-card">
                <div className="all-event">
                  <div className="event-card-content">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                  </div>
                  <div className="event-card-content-hover">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                    <p>
                      Massive retrenchments happening in Altisource Bangalore,
                      in which hundreds of employees terminated even without a
                      prior notice. Karnataka State IT/ITeS Employees Union
                      #KITU strongly condemns this...
                    </p>
                    <a href="/detailsnews">
                      <button className="event-butt">Know More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="event-card">
                <div className="all-event">
                  <div className="event-card-content">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                  </div>
                  <div className="event-card-content-hover">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                    <p>
                      Massive retrenchments happening in Altisource Bangalore,
                      in which hundreds of employees terminated even without a
                      prior notice. Karnataka State IT/ITeS Employees Union
                      #KITU strongly condemns this...
                    </p>
                    <a href="/detailsnews">
                      <button className="event-butt">Know More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="event-card">
                <div className="all-event">
                  <div className="event-card-content">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                  </div>
                  <div className="event-card-content-hover">
                    <h5>
                      KITU against Altisource on the issue of mass retrenchments
                    </h5>
                    <p>
                      Massive retrenchments happening in Altisource Bangalore,
                      in which hundreds of employees terminated even without a
                      prior notice. Karnataka State IT/ITeS Employees Union
                      #KITU strongly condemns this...
                    </p>
                    <a href="/detailsnews">
                      <button className="event-butt">Know More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </div>
  );
}

export default StudyandResearchcenter;
