import React, { useEffect, useState } from "react";
import "../styles/Mostpopular.css";
import Carousel from "react-grid-carousel";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const MostPopular = () => {
  const navigate = new useNavigate();
  const [NewData, setNewData] = useState([]);
  const GetMostPopuler = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getmostpopuler`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [CommunityOutreach, setCommunityOutreach] = useState([]);
  const GetCommunityOutreach = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getcommunityoutreach`
      );
      if (getdata.status === 200) {
        setCommunityOutreach(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [InNews, setInNews] = useState([]);
  console.log("in news",InNews)
  const GetInlinks = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getInlinks`
      );
      if (getdata.status === 200) {
        setInNews(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetMostPopuler();
    GetCommunityOutreach();
    GetInlinks();
  }, []);


  const renderCards = (NewData) => {
    return NewData.map((item, index) => (
      <div className="col-md-4" key={index}>
        <div className="card-container card-imagition">
          <div className="card my-card">
            <img src={`https://thekitu.com/News/${item?.image}`} alt="Image1" className="card-image" />
            <div className="card-content">
              <a href="/detailsnews" onClick={()=>navigate("/detailsnews",{ state: { item } })}>
                <h5>{item?.title?.length > 80 ? item?.title.slice(0, 80) + '...' : item?.title}</h5>
              </a>
              {/* <p>{parse(`<div>${item?.description?.length > 100 ? item?.description.slice(0, 100) + '...' : item?.description}</div>`)}</p> */}
              {/* <p>{parse(`<div>${item?.description}</div>`)}</p> */}
              <p className="" dangerouslySetInnerHTML={{ __html: item?.description?.length > 70 ? item?.description.slice(0, 70) + '...' : item?.description }} />
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderCards2 = (NewData) => {
    return NewData.map((item, index) => (
      <div className="col-md-4" key={index}>
        <div className="card-container card-imagition">
          <div className="card my-card">
            <img src={`https://thekitu.com/News/${item?.image}`} alt="Image1" className="card-image" />
            <div className="card-content">
              <a href="/detailsnews" onClick={()=>navigate("/detailsnews",{ state: { item } })}>
                <h5>{item?.title?.length > 80 ? item?.title.slice(0, 80) + '...' : item?.title}</h5>
              </a>
              {/* <p>{parse(`<div>${item?.description?.length > 100 ? item?.description.slice(0, 100) + '...' : item?.description}</div>`)}</p> */}
              {/* <p>{parse(`<div>${item?.description}</div>`)}</p> */}
              <p className="" dangerouslySetInnerHTML={{ __html: item?.description?.length > 70 ? item?.description.slice(0, 70) + '...' : item?.description }} />
            </div>
          </div>
        </div>
      </div>
    ));
  };


  return (
    <div>
      <div className="">
        <div className="row">
          <div
            className="col-sm-9 p-5 most-populer"
            style={{ position: "relative", marginTop: "-54px" }}
          >
            <div className="lastestnews-event" style={{ marginLeft: "0px" }}>
              <h3>Most Popular</h3>
            </div>
            {/* <div className="row">
              <div className="col-md-3">
                <div class="card-container card-imagition">
                  <div class="card my-card">
                    <img
                      src="/Assets/image2.jpg"
                      alt="Image1"
                      class="card-image"
                    />
                    <div class="card-content">
                      <a href="/detailsnews">
                        {" "}
                        <h5>
                          Karnataka State IT/ITeS Employees Union (KITU)
                          Headquarters is inaugurated in Madiwala, Bangalore
                        </h5>
                      </a>
                      <p>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore on
                        4th December 2022. augurated ...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 ">
                <div class="card-container card-imagition">
                  <div class="card my-card">
                    <img
                      src="/Assets/image2.jpg"
                      alt="Image1"
                      class="card-image"
                    />
                    <div class="card-content">
                      <a href="/detailsnews">
                        {" "}
                        <h5>
                          Karnataka State IT/ITeS Employees Union (KITU)
                          Headquarters is inaugurated in Madiwala, Bangalore
                        </h5>
                      </a>
                      <p>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore on
                        4th December 2022. augurated ...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div class="card-container card-imagition">
                  <div class="card my-card">
                    <img
                      src="/Assets/image2.jpg"
                      alt="Image1"
                      class="card-image"
                    />
                    <div class="card-content">
                      <a href="/detailsnews">
                        {" "}
                        <h5>
                          Karnataka State IT/ITeS Employees Union (KITU)
                          Headquarters is inaugurated in Madiwala, Bangalore
                        </h5>
                      </a>
                      <p>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore on
                        4th December 2022. augurated ...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div class="card-container card-imagition">
                  <div class="card my-card">
                    <img
                      src="/Assets/image2.jpg"
                      alt="Image1"
                      class="card-image"
                    />
                    <div class="card-content">
                      <a href="/detailsnews">
                        {" "}
                        <h5>
                          Karnataka State IT/ITeS Employees Union (KITU)
                          Headquarters is inaugurated in Madiwala, Bangalore
                        </h5>
                      </a>
                      <p>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore on
                        4th December 2022. augurated ...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="row  nnn">
              <Carousel cols={4} rows={1} gap={5} loop>
                {NewData?.map((item,index) => {
                  return (
                    <Carousel.Item  key={index}>
                      <div className="">
                        <div class="card-container  card-imagition">
                          <div class="card my-card">
                            <img
                              src={`https://thekitu.com/MostPopuler/${item?.image}`}
                              alt="Image1"
                              class="card-image"
                            />
                            <div class="card-content">
                              <a
                                href="/mostpopulerdetails"
                                onClick={() =>
                                  navigate("/mostpopulerdetails", {
                                    state: { item },
                                  })
                                }
                              >
                                {" "}
                                <h5>
                                  {item?.title?.length > 80
                                    ? item?.title.slice(0, 80) + "..."
                                    : item?.title}
                                </h5>
                              </a>

                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item?.description?.length > 70
                                      ? item?.description.slice(0, 70) + "..."
                                      : item?.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })} */}


<div className="row">
{renderCards(NewData.slice(0, 3))}
</div>



                {/* <Carousel.Item>
                  <div className="">
                    <div class="card-container card-imagition">
                      <div class="card my-card">
                        <img
                          src="/Assets/image2.jpg"
                          alt="Image1"
                          class="card-image"
                        />
                        <div class="card-content">
                          <a href="/detailsnews">
                            {" "}
                            <h5>
                              Karnataka State IT/ITeS Employees Union (KITU)
                              Headquarters is inaugurated in Madiwala, Bangalore
                            </h5>
                          </a>
                          <p>
                            Karnataka State IT/ITeS Employees Union (KITU)
                            Headquarters is in Madiwala, Bangalore on 4th
                            December 2022. augurated ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="">
                    <div class="card-container card-imagition">
                      <div class="card my-card">
                        <img
                          src="/Assets/image2.jpg"
                          alt="Image1"
                          class="card-image"
                        />
                        <div class="card-content">
                          <a href="/detailsnews">
                            {" "}
                            <h5>
                              Karnataka State IT/ITeS Employees Union (KITU)
                              Headquarters is inaugurated in Madiwala, Bangalore
                            </h5>
                          </a>
                          <p>
                            Karnataka State IT/ITeS Employees Union (KITU)
                            Headquarters is in Madiwala, Bangalore on 4th
                            December 2022. augurated ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="">
                    <div class="card-container card-imagition">
                      <div class="card my-card">
                        <img
                          src="/Assets/image2.jpg"
                          alt="Image1"
                          class="card-image"
                        />
                        <div class="card-content">
                          <a href="/detailsnews">
                            {" "}
                            <h5>
                              Karnataka State IT/ITeS Employees Union (KITU)
                              Headquarters is inaugurated in Madiwala, Bangalore
                            </h5>
                          </a>
                          <p>
                            Karnataka State IT/ITeS Employees Union (KITU)
                            Headquarters is in Madiwala, Bangalore on 4th
                            December 2022. augurated ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="">
                    <div class="card-container card-imagition">
                      <div class="card my-card">
                        <img
                          src="/Assets/image2.jpg"
                          alt="Image1"
                          class="card-image"
                        />
                        <div class="card-content">
                          <a href="/detailsnews">
                            {" "}
                            <h5>
                              Karnataka State IT/ITeS Employees Union (KITU)
                              Headquarters is inaugurated in Madiwala, Bangalore
                            </h5>
                          </a>
                          <p>
                            Karnataka State IT/ITeS Employees Union (KITU)
                            Headquarters is in Madiwala, Bangalore on 4th
                            December 2022. augurated ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="">
                    <div class="card-container card-imagition">
                      <div class="card my-card">
                        <img
                          src="/Assets/image2.jpg"
                          alt="Image1"
                          class="card-image"
                        />
                        <div class="card-content">
                          <a href="/detailsnews">
                            {" "}
                            <h5>
                              Karnataka State IT/ITeS Employees Union (KITU)
                              Headquarters is inaugurated in Madiwala, Bangalore
                            </h5>
                          </a>
                          <p>
                            Karnataka State IT/ITeS Employees Union (KITU)
                            Headquarters is in Madiwala, Bangalore on 4th
                            December 2022. augurated ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item> */}
              {/* </Carousel>
            </div> */}
            <div className="lastestnews-event" style={{ marginLeft: "0px" }}>
              <h3>Community Outreach</h3>
            </div>
            <div className="row ">
{renderCards2(CommunityOutreach.slice(0, 3))}
</div>
            {/* <div className="row">
              {CommunityOutreach?.map((item) => {
                return (
                  <div className="col-md-3">
                    <div class="card-container card-imagition">
                      <div class="card my-card">
                        <img
                          src={`https://thekitu.com/CommunityOutreach/${item?.image}`}
                          alt="Image1"
                          class="card-image"
                        />
                        <div class="card-content">
                          <a
                            href="/communityoutreachdetails"
                            onClick={() =>
                              navigate("/communityoutreachdetails", {
                                state: { item },
                              })
                            }
                          >
                            {" "}
                            <h5>
                              {item?.title?.length > 60
                                ? item?.title.slice(0, 60) + "..."
                                : item?.title}
                            </h5>
                          </a>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                item?.description?.length > 90
                                  ? item?.description.slice(0, 90) + "..."
                                  : item?.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })} */}
              {/* <div className="col-md-3">
                <div class="card-container card-imagition">
                  <div class="card my-card">
                    <img
                      src="/Assets/image2.jpg"
                      alt="Image1"
                      class="card-image"
                    />
                    <div class="card-content">
                      <a href="/detailsnews">
                        {" "}
                        <h5>
                          Karnataka State IT/ITeS Employees Union (KITU)
                          Headquarters is inaugurated in Madiwala, Bangalore
                        </h5>
                      </a>
                      <p>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore on
                        4th December 2022. augurated ...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 ">
                <div class="card-container card-imagition">
                  <div class="card my-card">
                    <img
                      src="/Assets/image2.jpg"
                      alt="Image1"
                      class="card-image"
                    />
                    <div class="card-content">
                      <a href="/detailsnews">
                        {" "}
                        <h5>
                          Karnataka State IT/ITeS Employees Union (KITU)
                          Headquarters is inaugurated in Madiwala, Bangalore
                        </h5>
                      </a>
                      <p>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore on
                        4th December 2022. augurated ...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div class="card-container card-imagition">
                  <div class="card my-card">
                    <img
                      src="/Assets/image2.jpg"
                      alt="Image1"
                      class="card-image"
                    />
                    <div class="card-content">
                      <a href="/detailsnews">
                        {" "}
                        <h5>
                          Karnataka State IT/ITeS Employees Union (KITU)
                          Headquarters is inaugurated in Madiwala, Bangalore
                        </h5>
                      </a>
                      <p>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore on
                        4th December 2022. augurated ...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div class="card-container card-imagition">
                  <div class="card my-card">
                    <img
                      src="/Assets/image2.jpg"
                      alt="Image1"
                      class="card-image"
                    />
                    <div class="card-content">
                      <a href="/detailsnews">
                        {" "}
                        <h5>
                          Karnataka State IT/ITeS Employees Union (KITU)
                          Headquarters is inaugurated in Madiwala, Bangalore
                        </h5>
                      </a>
                      <p>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore on
                        4th December 2022. augurated ...
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            {/* </div> */}
          </div>

          <div className="col-md-3" style={{ marginTop: "-54px" }}>
            <div className="lastestnews-updates">
              <h3>In News</h3>
            </div>
            <div className="mt-4">
              <div className="in-news ">
                {InNews?.slice(0, 8)?.map((item, i) => {
                  return (
                    <div className="row mt-2 ">
                  
                      <div className="col-md-4  d-flex justify-content-center" >
                      <a href={item?.links}  target="_blank" rel="noopener noreferrer">
                        <img
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "7px",
                          }}
                          src={`https://thekitu.com/InLinks/${item?.image}`}
                          alt=""
                        />
                         </a>
                      </div>
                     
                  
                      <div className="col-md-8">
                        <div style={{wordWrap: "break-word"}} className="text-center">
                        <a href={item?.links} style={{textDecoration:"none",color:"black",fontWeight:"700"}} target="_blank" rel="noopener noreferrer">
                          {item?.title}
                        </a>
                          </div>
                     
                      </div>
                    </div>
                  );
                })}

                {/* <div className="row mt-2">
                  <div className="col-md-4">
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "7px",
                      }}
                      src="/Assets/update1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-md-8">
                    <a href="/jkokdlakds" target="_blank">
                      Bengaluru employee union alleges Byju's forcing its
                      employees to resign
                    </a>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "7px",
                      }}
                      src="/Assets/update1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-md-8">
                    <a href="/jkokdlakds" target="_blank">
                      Bengaluru employee union alleges Byju's forcing its
                      employees to resign
                    </a>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "7px",
                      }}
                      src="/Assets/update1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-md-8">
                    <a href="/jkokdlakds" target="_blank">
                      Bengaluru employee union alleges Byju's forcing its
                      employees to resign
                    </a>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "7px",
                      }}
                      src="/Assets/update1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-md-8">
                    <a href="/jkokdlakds" target="_blank">
                      Bengaluru employee union alleges Byju's forcing its
                      employees to resign
                    </a>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "7px",
                      }}
                      src="/Assets/update1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-md-8">
                    <a href="/jkokdlakds" target="_blank">
                      Bengaluru employee union alleges Byju's forcing its
                      employees to resign
                    </a>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "7px",
                      }}
                      src="/Assets/update1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-md-8">
                    <a href="/jkokdlakds" target="_blank">
                      Bengaluru employee union alleges Byju's forcing its
                      employees to resign
                    </a>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "7px",
                      }}
                      src="/Assets/update1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-md-8">
                    <a href="/jkokdlakds" target="_blank">
                      Bengaluru employee union alleges Byju's forcing its
                      employees to resign
                    </a>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "7px",
                      }}
                      src="/Assets/update1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-md-8">
                    <a href="/jkokdlakds" target="_blank">
                      Bengaluru employee union alleges Byju's forcing its
                      employees to resign
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostPopular;
