import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";


function MembershipList() {

  const [NewData, setNewData] = useState([]);
  const getMembership = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/user/getmembership`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.membership);
        console.log("memebership",NewData)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Deletemembership = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this news item?"
    );

    if (!confirmDelete) {
      return; // Exit if the user cancels the action
    }

    try {
      const res = await axios.delete(
        `https://thekitu.com/api/user/deletemembership/${id}`
      );

      if (res.status === 200) {
        alert(res.data.success);
        getMembership(); // Refresh the news list after successful deletion
      }
    } catch (error) {
      console.error(error);
      alert(
        error.response?.data?.error ||
          "An error occurred while deleting the news"
      );
    }
  };

  useEffect(() => {
    getMembership();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between mt-4">
        <div>
          <h3>Membership List</h3>
        </div>
        <div></div>
      </div>
      <div className="mt-3">
        <Table bordered>
          <thead className="tablecolor">
            <tr>
              <th>Sl no.</th>
              <th>Membership Id</th>
              <th>Name</th>
              <th>Phone No</th>
              <th>Gender</th>
              <th>Company</th>
              <th>Pay Type</th>
              <th>Pay Id</th>
              <th>Designation</th>
              <th>Residential Address</th>
              <th>Donation Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {NewData?.map((item, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{item?._id}</td>
                  <td>{item?.name}</td>
                  <td>{item?.mobileno}</td>
                  <td>{item?.gender}</td>
                  <td>{item?.company}</td>
                  <td>{item?.formtype}</td>
                  <td>{item?.payid}</td>
                  <td>{item?.designation}</td>
                  <td>{item?.address}</td>
                  <td>{item?.amount}/-</td>
                  <td>
                    {" "}
                    <MdDelete
                      className="mt-4"
                      onClick={() => Deletemembership(item?._id)}
                      style={{
                        color: "red",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    />
                 
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default MembershipList;
