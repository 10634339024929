import React, { useEffect, useState } from "react";
import "../styles/Latestnews.css";
import axios from "axios";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";


const LatestNews = () => {

  const navigate = new useNavigate()

  const [NewData, setNewData] = useState([]);
  const GetNews = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getnewsandevent`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Campaign, setCampaign] = useState([]);
  const GetCampign = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getCampaign`
      );
      if (getdata.status === 200) {
        setCampaign(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetNews();
    GetCampign();
  }, [])


  const renderCards = (NewData) => {
    return NewData.map((item, index) => (
      <div className="col-md-4" key={index}>
        <div className="card-container card-imagition">
          <div className="card my-card">
            <img src={`https://thekitu.com/News/${item?.image}`} alt="Image1" className="card-image" />
            <div className="card-content">
              <a href="/detailsnews" onClick={()=>navigate("/detailsnews",{ state: { item } })}>
                <h5>{item?.title?.length > 80 ? item?.title.slice(0, 80) + '...' : item?.title}</h5>
              </a>
              {/* <p>{parse(`<div>${item?.description?.length > 100 ? item?.description.slice(0, 100) + '...' : item?.description}</div>`)}</p> */}
              {/* <p>{parse(`<div>${item?.description}</div>`)}</p> */}
              <p className="" dangerouslySetInnerHTML={{ __html: item?.description?.length > 70 ? item?.description.slice(0, 70) + '...' : item?.description }} />
            </div>
          </div>
        </div>
      </div>
    ));
  };


  const renderSecondRow = (NewData) => {
    return NewData.map((item, index) => (
      <div className="col-md-3" key={index}>
        <div className="card-container card-imagition">
          <div className="card my-card">
            <img src={`https://thekitu.com/News/${item?.image}`} alt="Image1" className="card-image" />
            <div className="card-content">
              <a href="/detailsnews" onClick={()=>navigate("/detailsnews",{ state: { item } })}>
              <h5>{item?.title?.length > 60 ? item?.title.slice(0, 60) + '...' : item?.title}</h5>
              </a>
              <p dangerouslySetInnerHTML={{ __html: item?.description?.length > 80 ? item?.description.slice(0, 80) + '...' : item?.description }} />
            </div>
          </div>
        </div>
      </div>
    ));
  };
  
  return (
    <div>
      <div className="row">
        <div className="col-md-9 p-5  " style={{ position: "relative" }}>
          <div className="lastestnews-event" style={{ marginLeft: "0px" }}>
            <h3>Latest News & Events</h3>
      
          </div>
          <div className="row">
          {renderCards(NewData.slice(0, 3))}
            {/* <div className="col-md-4 ">
              <div class="card-container card-imagition">
                <div class="card my-card">
                  <img
                    src="/Assets/image2.jpg"
                    alt="Image1"
                    class="card-image"
                  />
                  <div class="card-content">
                    <a href="/detailsnews">
                      {" "}
                      <h5>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore
                      </h5>
                    </a>
                    <p>
                      Karnataka State IT/ITeS Employees Union (KITU)
                      Headquarters Bangalore on 4th CITU Karnataka State General
                      Secretary inaugurated ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div class="card-container card-imagition">
                <div class="card my-card">
                  <img
                    src="/Assets/image2.jpg"
                    alt="Image1"
                    class="card-image"
                  />
                  <div class="card-content">
                    <a href="/detailsnews">
                      {" "}
                      <h5>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore
                      </h5>
                    </a>
                    <p>
                      Karnataka State IT/ITeS Employees Union (KITU)
                      Headquarters , Bangalore on 4th CITU Karnataka State
                      General Secretary inaugurated ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4  ">
              <div class="card-container card-imagition">
                <div class="card my-card">
                  <img
                    src="/Assets/image2.jpg"
                    alt="Image1"
                    class="card-image"
                  />
                  <div class="card-content">
                    <a href="/detailsnews">
                      {" "}
                      <h5>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore
                      </h5>
                    </a>
                    <p>
                      Karnataka State IT/ITeS Employees Union (KITU)
                      Headquarters is Bangalore on 4th CITU Karnataka State
                      General Secretary inaugurated ...
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="row">
          {renderSecondRow(NewData.slice(3, 7))}
            {/* <div className="col-md-3">
              <div class="card-container card-imagition">
                <div class="card my-card">
                  <img
                    src="/Assets/image2.jpg"
                    alt="Image1"
                    class="card-image"
                  />
                  <div class="card-content">
                    <a href="/detailsnews">
                      {" "}
                      <h5>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore
                      </h5>
                    </a>
                    <p>
                      Karnataka State IT/ITeS Employees Union (KITU)
                      Headquarters is inaugurated in Madiwala, Bangalore on 4th
                      December 2022. augurated ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <div class="card-container card-imagition">
                <div class="card my-card">
                  <img
                    src="/Assets/image2.jpg"
                    alt="Image1"
                    class="card-image"
                  />
                  <div class="card-content">
                    <a href="/detailsnews">
                      {" "}
                      <h5>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore
                      </h5>
                    </a>
                    <p>
                      Karnataka State IT/ITeS Employees Union (KITU)
                      Headquarters is inaugurated in Madiwala, Bangalore on 4th
                      December 2022. augurated ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div class="card-container card-imagition">
                <div class="card my-card">
                  <img
                    src="/Assets/image2.jpg"
                    alt="Image1"
                    class="card-image"
                  />
                  <div class="card-content">
                    <a href="/detailsnews">
                      {" "}
                      <h5>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore
                      </h5>
                    </a>
                    <p>
                      Karnataka State IT/ITeS Employees Union (KITU)
                      Headquarters is inaugurated in Madiwala, Bangalore on 4th
                      December 2022. augurated ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div class="card-container card-imagition">
                <div class="card my-card">
                  <img
                    src="/Assets/image2.jpg"
                    alt="Image1"
                    class="card-image"
                  />
                  <div class="card-content">
                    <a href="/detailsnews">
                      {" "}
                      <h5>
                        Karnataka State IT/ITeS Employees Union (KITU)
                        Headquarters is inaugurated in Madiwala, Bangalore
                      </h5>
                    </a>
                    <p>
                      Karnataka State IT/ITeS Employees Union (KITU)
                      Headquarters is inaugurated in Madiwala, Bangalore on 4th
                      December 2022. augurated ...
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="col-md-3 ">
          <div className="lastestnews-updates ">
            <h3>Campaign</h3>
          </div>
          <div className="mt-4  d-flex justify-content-center flex-column align-items-center">
            {Campaign?.map((item,index)=>{
              if(index <= 1){
                return(
                  <div style={{cursor:"pointer"}} className="campaign-img mt-2" onClick={()=>navigate("/campaigndetails",{ state: { item } })}>
                  <img  src={`https://thekitu.com/Campaign/${item?.image}`} alt="" />
                </div>
                )
              }

            })}
         
            {/* <div className="campaign-img mt-2">
              <img src="/Assets/image2.jpg" alt="" />
            </div>
            <div className="campaign-img mt-2">
              <img src="/Assets/image2.jpg" alt="" />
            </div>
            <div className="campaign-img mt-2">
              <img src="/Assets/image2.jpg" alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
