import React, { useEffect, useState } from "react";
import "./Css/admin.css";
import { Container } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function DashBoard() {
  const [NewData, setNewData] = useState([]);
  const GetNews = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getnewsandevent`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Mostpopuler, setMostpopuler] = useState([]);
  const GetMostPopuler = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getmostpopuler`
      );
      if (getdata.status === 200) {
        setMostpopuler(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [CommunityOutreach, setCommunityOutreach] = useState([]);
  const GetCommunityOutreach = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getcommunityoutreach`
      );
      if (getdata.status === 200) {
        setCommunityOutreach(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [StudyResearchCenter, setStudyResearchCenter] = useState([]);
  const GetStudyResearchCenter = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getstudyresearchcenter`
      );
      if (getdata.status === 200) {
        setStudyResearchCenter(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Campaign, setCampaign] = useState([]);
  const GetCampign = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getCampaign`
      );
      if (getdata.status === 200) {
        setCampaign(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [InNewsLinks, setInNewsLinks] = useState([]);
  const GetInlinks = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getInlinks`
      );
      if (getdata.status === 200) {
        setInNewsLinks(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetInlinks()
    GetCampign()
    GetNews();
    GetMostPopuler();
    GetCommunityOutreach();
    GetStudyResearchCenter();
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <Container fluid className="dashboard-container">
      <div className="mb-4">
     
        <div className="dashboard-heading text-center">
          <h2 style={{borderBottom: "4px solid #d31919"}}>Admin Dashboard</h2>
        </div>
      
    </div>

        <div className="d-flex gap-4 justify-content-around">
          <div className="dashboard-box" onClick={()=>navigate('/adminlatestnews')}>
            <div style={{borderBottom:"4px solid white"}}>
              <h3>Latest News & Events</h3>
            </div>
            <div>
              <b>
                Total No : <span>{NewData?.length}</span>
              </b>
            </div>
          </div>
          <div className="dashboard-box" onClick={()=>navigate('/mostpopularnews')}>
            <div style={{borderBottom:"4px solid white"}}>
              <h3>Most Populer</h3>
            </div>
            <div>
              <b>
                Total No : <span>{Mostpopuler?.length}</span>
              </b>
            </div>
          </div>
          <div className="dashboard-box" onClick={()=>navigate('/admincommunityoutreach')}>
            <div style={{borderBottom:"4px solid white"}}>
              <h3>Community Outreach</h3>
            </div>
            <div>
              <b>
                Total No : <span>{CommunityOutreach?.length}</span>
              </b>
            </div>
          </div>
        </div><br/>

        <div className="d-flex gap-4 justify-content-around">
          <div className="dashboard-box" onClick={()=>navigate('/adminstudyresearchcenter')}>
            <div style={{borderBottom:"4px solid white"}}>
              <h3>Study & Research Center</h3>
            </div>
            <div>
              <b>
                Total No : <span>{StudyResearchCenter?.length}</span>
              </b>
            </div>
          </div>
          <div className="dashboard-box" onClick={()=>navigate('/campaign')}>
            <div style={{borderBottom:"4px solid white"}}>
              <h3>Campaign</h3>
            </div>
            <div>
              <b>
                Total No : <span>{Campaign?.length}</span>
              </b>
            </div>
          </div>
          <div className="dashboard-box" onClick={()=>navigate('/innews')}>
            <div style={{borderBottom:"4px solid white"}}>
              <h3>In News</h3>
            </div>
            <div>
              <b>
                Total No : <span>{InNewsLinks?.length}</span>
              </b>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default DashBoard;
