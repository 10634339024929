import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";

function MostPopularNews() {
  const [eventid, setEventId] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [NewData, setNewData] = useState([]);
  const GetMostPopuler = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getmostpopuler`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const DeleteMostPopuler = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this news item?"
    );

    if (!confirmDelete) {
      return; // Exit if the user cancels the action
    }

    try {
      const res = await axios.delete(
        `https://thekitu.com/api/admin/deletemostpopuler/${id}`
      );

      if (res.status === 200) {
        alert(res.data.success);
        GetMostPopuler(); // Refresh the news list after successful deletion
      }
    } catch (error) {
      console.error(error);
      alert(
        error.response?.data?.error ||
          "An error occurred while deleting the news"
      );
    }
  };

  const [Image, setImage] = useState("");
  const [Title, setTitle] = useState("");
  const [Description, setDescription] = useState("");

  const [Image2, setImage2] = useState("");
  const [Title2, setTitle2] = useState("");
  const [Description2, setDescription2] = useState("");

  const AddMostPopuler = async () => {
    if (!Image) {
      return alert("Please select an image");
    }
    if (!Title) {
      return alert("Please enter a title");
    }
    if (!Description) {
      return alert("Please enter a description");
    }

    try {
      const formData = new FormData();
      formData.append("image", Image); // Make sure the name here matches the field in your backend
      formData.append("title", Title);
      formData.append("description", Description);

      const config = {
        url: "/addmostpopuler",
        method: "post",
        baseURL: "https://thekitu.com/api/admin",
        data: formData,
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        GetMostPopuler();
        handleClose();
        setImage("");
        setTitle("");
        setDescription("");
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  useEffect(() => {
    GetMostPopuler();
  }, []);
  const [expandedItems, setExpandedItems] = useState({}); // Track expanded items per row
  const maxWords = 150;

  const toggleReadMore = (id) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the expanded state for the clicked item using its unique id
    }));
  };

  const updateMostPopularNews = async () => {
    try {
      const formData = new FormData();
      formData.append("image", Image2); // Make sure the name here matches the field in your backend
      formData.append("title", Title2);
      formData.append("description", Description2);

      const config = {
        url: `/updatemostpopular/${eventid}`,
        method: "put",
        baseURL: "https://thekitu.com/api/admin",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const res = await axios(config);
      console.log("res", res);
      if (res.status === 200) {
        alert("data updated successfully...");
        GetMostPopuler();
        handleClose2()
      }
    } catch (error) {
      console.log("error", error);
      alert("something went wrong");
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between mt-4">
        <div>
          <h3>Most Populer </h3>
        </div>
        <div>
          <Button variant="danger" onClick={handleShow}>
            Add
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <Table bordered>
          <thead className="tablecolor">
            <tr>
              <th>Sl.No</th>
              <th>Date</th>
              <th>Image</th>
              <th>Title</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {NewData?.map((item, i) => {
              const description = item?.description || "";
              const words = description.split(" ");
              const isExpanded = expandedItems[item._id]; // Use item._id to track the expanded state
              const shouldShowReadMore = words.length > maxWords;
              return (
                <tr>
                  <td>{++i}</td>
                  <td>
                    <div style={{ width: "100px" }}>
                      {moment(item?.createdAt)?.format("DD-MM-YYYY")}
                    </div>
                  </td>
                  <td>
                    <img
                      src={`https://thekitu.com/MostPopuler/${item?.image}`}
                      alt="newsimage"
                      style={{ width: "150px", height: "150px" }}
                    />
                  </td>
                  <td>{item?.title} </td>
                  <td>
                    {/* {parse(`<div>${item?.description}</div>`)} */}

                    <div>
                      {isExpanded
                        ? parse(`<div>${description}</div>`) // Show full description
                        : parse(
                            `<div>${words
                              .slice(0, maxWords)
                              .join(" ")}...</div>`
                          )}{" "}
                      {/* Show truncated description */}
                    </div>

                    {shouldShowReadMore && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "700",
                          cursor: "pointer",
                        }}
                        onClick={() => toggleReadMore(item._id)}
                      >
                        {isExpanded ? "Read Less" : "Read More..."}
                      </div>
                    )}
                  </td>

                  <td>
                    <MdDelete
                      onClick={() => DeleteMostPopuler(item?._id)}
                      style={{
                        color: "red",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    />
                    <FaRegEdit
                      style={{
                        color: "red",
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEventId(item?._id);
                        setTitle2(item?.title);
                        setDescription2(item?.description);
                        handleShow2();
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Most Populer </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row">
              <div className="col-sm-6">
                <Form.Label>
                  Image<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <Form.Label>
                Title<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="enter title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="row">
              <Form.Label>
                Description<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <CKEditor
                data={Description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
                editor={ClassicEditor}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddMostPopuler}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Most Populer </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row">
              <div className="col-sm-6">
                <Form.Label>
                  Image<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setImage2(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <Form.Label>
                Title<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                // placeholder="enter title"
                value={Title2}
                onChange={(e) => setTitle2(e.target.value)}
              />
            </div>
            <div className="row">
              <Form.Label>
                Description<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <CKEditor
                data={Description2}
                value={Description2}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription2(data);
                }}
                editor={ClassicEditor}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="danger" onClick={updateMostPopularNews}>
           Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MostPopularNews;
