import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FaEdit } from "react-icons/fa";

function SocialMedialLinks() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [NewData, setNewData] = useState([]);
  const GetSocialMedia = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getsocialmedialink`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const [Media, setMedia] = useState("");
  const [MediaLink, setMediaLink] = useState("");

  const AddSocialMedia = async () => {
    if (!Media) {
      return alert("Select media");
    }
    if (!MediaLink) {
      return alert("pase the link..");
    }

    try {
      const config = {
        url: "/addsocialmedialink",
        method: "post",
        baseURL: "https://thekitu.com/api/admin",
        data: {
          media: Media,
          link:MediaLink
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        GetSocialMedia();
        handleClose();
        setMedia("");
        setMediaLink("");
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  const [SocialLinkid, setSocialLinkid] = useState({})

  useEffect(() => {
    if(SocialLinkid){
      setMedia(SocialLinkid?.media || "");
      setMediaLink(SocialLinkid?.link || "");
    }

  }, [SocialLinkid])

  const EditSocialLinks = async () => {
    try {
      const config = {
        url: "/updatesociallinks",
        method: "put",
        baseURL: "https://thekitu.com/api/admin",
        data: {
          id:SocialLinkid?._id,
          media: Media,
          link:MediaLink,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        GetSocialMedia();
        handleClose1();
        setMedia("");
        setMediaLink("");
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }   
  };

  useEffect(() => {
    GetSocialMedia();
  }, []);



  return (
    <div>
      <div className="d-flex justify-content-between mt-4">
        <div>
          <h3>Social Media Links </h3>
        </div>
        <div>
          {
            NewData.length > 3 ? '' :(   <Button variant="danger" onClick={handleShow}>
              Add
            </Button>)
          }
       
        </div>
      </div>
      <div className="mt-3">
        <Table bordered>
          <thead className="tablecolor">
            <tr>
              <th>Sl.No</th>
              <th>Media</th>
              <th>Link</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {NewData?.map((item, i) => {      
              return (
                <tr>
                  <td>{++i}</td>                              
                  <td>{item?.media} </td>
                  <td>{item?.link} </td>              

                  <td>
                    <FaEdit
                      onClick={() => {
                        handleShow1();
                        setSocialLinkid(item);
                      }}
                      style={{
                        color: "green",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Social Media Links</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row">
              <Form.Select onChange={(e) => setMedia(e.target.value)}>
                <option>Select Social Media</option>
                <option value="YouTube">YouTube</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Twitter">Twitter</option>
              </Form.Select>
            </div>
            <div className="row">
              <Form.Label>
                Link<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Paste Link"
                onChange={(e) => setMediaLink(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddSocialMedia}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Social Media Links</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row">
              <Form.Select
              value={Media}
              onChange={(e) => setMedia(e.target.value)}>
                <option>Select Social Media</option>
                <option value="YouTube">YouTube</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Twitter">Twitter</option>
              </Form.Select>
            </div>
            <div className="row">
              <Form.Label>
                Link<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                value={MediaLink}
                as="textarea"
                rows={3}
                placeholder="Paste Link"
                onChange={(e) => setMediaLink(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger" onClick={EditSocialLinks}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SocialMedialLinks;
