import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FaRegEdit } from "react-icons/fa";

function GalleryAdmin() {

  const [eventid, setEventId] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [NewData, setNewData] = useState([]);
  const GetGalleryImages = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getGalleryImages`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const DeleteGalleryImages = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this news item?"
    );
    if (!confirmDelete) {
      return; // Exit if the user cancels the action
    }
    try {
      const res = await axios.delete(
        `https://thekitu.com/api/admin/deleteGalleryImages/${id}`
      );
      if (res.status === 200) {
        alert(res.data.success);
        GetGalleryImages(); // Refresh the news list after successful deletion
      }
    } catch (error) {
      console.error(error);
      alert(
        error.response?.data?.error ||
          "An error occurred while deleting the news"
      );
    }
  };

  const [Image, setImage] = useState("");
  const [Links, setLinks] = useState("");
  const [Description, setDescription] = useState("");

  
  const [Image2, setImage2] = useState("");

  const AddGalleryImages = async () => {
    if (!Image) {
      return alert("Please select an image");
    }
    // if (!Links) {
    //   return alert("Please past link");
    // }
  //   if (!Description) {
  //     return alert("Please enter a description");
  //   }

    try {
      const formData = new FormData();
      formData.append("image", Image); // Make sure the name here matches the field in your backend
      // formData.append("links", Links);
      // formData.append("description", Description);

      const config = {
        url: "/addGalleryImages",
        method: "post",
        baseURL: "https://thekitu.com/api/admin",
        data: formData,
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        GetGalleryImages();
        handleClose();
        setImage("");
        // setLinks("");
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  const updateGallery = async () => {
    try {
      const formData = new FormData();
      formData.append("image", Image2); // Make sure the name here matches the field in your backend
      
      const config = {
        url: `/updategallery/${eventid}`,
        method: "put",
        baseURL: "https://thekitu.com/api/admin",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const res = await axios(config);
  
      if (res.status === 200) {
        alert("data updated successfully...");
        GetGalleryImages();
        handleClose2()
      }
    } catch (error) {
      console.log("error", error);
      alert("something went wrong");
    }
  }

  useEffect(() => {
    GetGalleryImages();
  }, []);
  return (
    <div>
    <div className="d-flex justify-content-between mt-4">
      <div>
        <h3>Gallery Images</h3>
      </div>
      <div>
        <Button variant="danger" onClick={handleShow}>
          Add
        </Button>
      </div>
    </div>
    <div className="mt-3">
      <Table bordered>
        <thead className="tablecolor">
          <tr>
            <th>Sl.No</th>
            <th>Date</th>
            <th>Image</th>
            {/* <th>Links</th> */}
            {/* <th>Description</th> */}
            {/* <th>Status</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {NewData?.map((item, i) => {
            return (
              <tr>
                <td>{++i}</td>
                <td>
                  <div style={{ width: "100px" }}>
                    {moment(item?.createdAt)?.format("DD-MM-YYYY")}
                  </div>
                </td>
                <td>
                  <img
                    src={`https://thekitu.com/Gallery/${item?.image}`}
                    alt="newsimage"
                    style={{ width: "150px", height: "150px" }}
                  />
                </td>
                {/* <td>{item?.title} </td> */}
                {/* <td>{parse(`<div>${item?.description}</div>`)}</td> */}
                {/* <td>block</td> */}
                <td>
                  <MdDelete
                    onClick={() => DeleteGalleryImages(item?._id)}
                    style={{
                      color: "red",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />

<FaRegEdit
                      style={{
                        color: "red",
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEventId(item?._id);
                        setImage2(item?.image)
                        handleShow2();
                      }}
                    />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>

    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Gallery Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div className="row">
            <div className="col-sm-6">
              <Form.Label>
                Image<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
          </div>
          {/* <div className="row">
            <Form.Label>
              Link<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="paste link"
              onChange={(e) => setLinks(e.target.value)}
            />
          </div> */}
          {/* <div className="row">
            <Form.Label>
              Description<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <CKEditor
              data={Description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
              editor={ClassicEditor}
            />
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={AddGalleryImages}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>


    <Modal
      show={show2}
      onHide={handleClose2}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Gallery Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div className="row">
            <div className="col-sm-6">
              <Form.Label>
                Image
              </Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setImage2(e.target.files[0])}
              />
            </div>
          </div>
        
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose2}>
          Close
        </Button>
        <Button variant="danger" onClick={updateGallery}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  )
}

export default GalleryAdmin