import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Image } from "react-bootstrap";
import { AiOutlineUnorderedList } from "react-icons/ai";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
  BiLogoGraphql,
  BiSolidDashboard,
  BiSolidDiscount,
  BiSolidUserAccount,
} from "react-icons/bi";
import {
  MdCategory,
  MdOutlineRealEstateAgent,
  MdProductionQuantityLimits,
} from "react-icons/md";
import { TbFileTypeVue, TbMapPinCode } from "react-icons/tb";
import { Link } from "react-router-dom";
import "./Css/admindashboard.css";
function AdminHeader(props) {
  const [active, setActive] = useState(true);
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [active8, setActive8] = useState(false);
  const [active9, setActive9] = useState(false);
  const [active10, setActive10] = useState(false);
  const [active11, setActive11] = useState(false);
  const [active12, setActive12] = useState(false);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  console.log("admin", admin);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const logout = () => {
    sessionStorage.removeItem("admin");
    window.location.assign("/");
  };

  useEffect(() => {
    if (!admin) {
      window.location.assign("/");
    }
  }, [admin]);
  return (
    <>
 <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>"Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={logout}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="container-fluid">
        <div className="admindashboard">
          <div className="row">
            <div className="row" style={{ backgroundColor: "#D90812" }}>
              <div className="col-md-2">
                <span>
                  <Image
                    src="./Assets/kitulogo.png"
                    alt="logo"
                    style={{
                      height: "92px",
                      width: "94px",
                      borderRadius: "49px",
                      padding: "6px",
                      marginLeft: "47px",
                      border: "4px solid white",
                    }}
                  ></Image>
                </span>
              </div>
              <div className="col-md-8">
                <h1 style={{ padding: "15px", color: "white" }}>
                  Admin Dashboard
                </h1>
              </div>
              <div className="col-md-2">
                {/* <DropdownButton className="admin-logout" title="Admin" style={{ padding: "24px" }}>
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </DropdownButton> */}
                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                    className="mt-3 admin-logout"
                      id="dropdown-basic"
                      style={{ padding: "10px" }}
                    >
                      {admin?.name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                      onClick={handleShow}
                       >Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className="col-md-2" style={{ backgroundColor: "#D90812" }}>
              <p
                style={{
                  padding: "3px",
                  color: "#ffff",
                  fontWeight: "600",
                  border: "1px solid white",
                  fontSize: "34px",
                  textAlign: "center",
                  borderRadius: "4px",
                  marginTop: "10px",
                }}
              >
                KITU
              </p>
              <div className="side-nav">
                <ul style={{ padding: "10px" }}>
                  <Link to="/dashboard">
                    <li
                      className={`li-remove ${active ? "active" : ""}`}
                      onClick={() => {
                        setActive(true);
                        setActive1(false);
                        setActive2(false);
                        setActive5(false);
                        setActive4(false);
                        setActive5(false);
                        setActive6(false);
                        setActive7(false);
                        setActive8(false);
                        setActive9(false);
                        setActive10(false);
                        setActive11(false);
                        setActive12(false);
                      }}
                    >
                      <div>
                        <span>
                          {" "}
                          <BiSolidDashboard
                            style={{ marginRight: "10px" }}
                          />{" "}
                          Dashboard
                        </span>
                      </div>
                    </li>
                  </Link>
                  <Link to="/adminlatestnews">
                    <li
                      className={`li-remove ${active1 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(true);
                        setActive2(false);
                        setActive5(false);
                        setActive4(false);
                        setActive5(false);
                        setActive6(false);
                        setActive7(false);
                        setActive8(false);
                        setActive9(false);
                        setActive10(false);
                        setActive11(false);
                        setActive12(false);
                      }}
                    >
                      <div>
                        <span>
                          {" "}
                          <BiLogoGraphql style={{ marginRight: "10px" }} />
                          Latest News
                        </span>
                      </div>
                    </li>
                  </Link>
                  <Link to="/mostpopularnews">
                    <li
                      className={`li-remove ${active2 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(false);
                        setActive2(true);
                        setActive3(false);
                        setActive4(false);
                        setActive5(false);
                        setActive4(false);
                        setActive5(false);
                        setActive6(false);
                        setActive7(false);
                        setActive8(false);
                        setActive9(false);
                        setActive10(false);
                        setActive11(false);
                        setActive12(false);
                      }}
                    >
                      <span>
                        <BiSolidDiscount style={{ marginRight: "5px" }} />
                        Most Popular
                      </span>
                    </li>
                  </Link>
                  <Link to="/admincommunityoutreach">
                    <li
                      className={`li-remove ${active3 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(true);
                        setActive4(false);
                        setActive5(false);
                        setActive6(false);
                        setActive7(false);
                        setActive8(false);
                        setActive9(false);
                        setActive10(false);
                        setActive11(false);
                        setActive12(false);
                      }}
                    >
                      <span>
                        <TbMapPinCode style={{ marginRight: "7px" }} />
                        Community Outreach
                      </span>
                    </li>
                  </Link>
                  <Link to="/adminstudyresearchcenter">
                    <li
                      className={`li-remove ${active4 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(true);
                        setActive5(false);
                        setActive6(false);
                        setActive7(false);
                        setActive8(false);
                        setActive9(false);
                        setActive8(false);
                        setActive9(false);
                        setActive10(false);
                        setActive11(false);
                        setActive12(false);
                      }}
                    >
                      <span>
                        <MdCategory style={{ marginRight: "2px" }} />
                        Study & Research Center
                      </span>
                    </li>
                  </Link>

                  <Link to="/campaign">
                    <li
                      className={`li-remove ${active5 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(false);
                        setActive5(true);
                        setActive6(false);
                        setActive7(false);
                        setActive8(false);
                        setActive9(false);
                        setActive10(false);
                        setActive11(false);
                        setActive12(false);
                      }}
                    >
                      <span>
                        <MdProductionQuantityLimits
                          style={{ marginRight: "2px" }}
                        />
                        Campaign
                      </span>
                    </li>
                  </Link>

                  <Link to="/innews">
                    <li
                      className={`li-remove ${active10 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(false);
                        setActive5(false);
                        setActive6(false);
                        setActive7(false);
                        setActive8(false);
                        setActive9(false);
                        setActive10(true);
                        setActive11(false);
                        setActive12(false);
                      }}
                    >
                      <span>
                        <MdProductionQuantityLimits
                          style={{ marginRight: "2px" }}
                        />
                        In News
                      </span>
                    </li>
                  </Link>

                  <Link to="/galleryadmin">
                    <li
                      className={`li-remove ${active6 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(false);
                        setActive5(false);
                        setActive6(true);
                        setActive7(false);
                        setActive8(false);
                        setActive9(false);
                        setActive10(false);
                        setActive11(false);
                        setActive12(false);
                      }}
                    >
                      <span>
                        <AiOutlineUnorderedList
                          style={{ marginRight: "2px" }}
                        />{" "}
                        Gallery
                      </span>
                    </li>
                  </Link>
                  <Link to="/aboutusadmin">
                    <li
                      className={`li-remove ${active7 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(false);
                        setActive5(false);
                        setActive6(false);
                        setActive7(true);
                        setActive8(false);
                        setActive9(false);
                        setActive10(false);
                        setActive11(false);
                        setActive12(false);
                      }}
                    >
                      <span>
                        {" "}
                        <TbFileTypeVue /> About Us
                      </span>
                    </li>
                  </Link>

                  <Link to="/admincontactus">
                    <li
                      className={`li-remove ${active8 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(false);
                        setActive5(false);
                        setActive6(false);
                        setActive7(false);
                        setActive8(true);
                        setActive9(false);
                        setActive10(false);
                        setActive11(false);
                        setActive12(false);
                      }}
                    >
                      <span>
                        <BiSolidUserAccount /> Enquiry
                      </span>
                    </li>
                  </Link>
                  <Link to="/membershiplist">
                    <li
                      className={`li-remove ${active9 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(false);
                        setActive5(false);
                        setActive6(false);
                        setActive7(false);
                        setActive8(false);
                        setActive9(true);
                        setActive();
                        setActive11(false);
                        setActive12(false);
                      }}
                    >
                      <span>
                        <MdOutlineRealEstateAgent />
                        Membership List
                      </span>
                    </li>
                  </Link>

                  <Link to="/socialmediallinks">
                    <li
                      className={`li-remove ${active11 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(false);
                        setActive5(false);
                        setActive6(false);
                        setActive7(false);
                        setActive8(false);
                        setActive9(false);
                        setActive();
                        setActive11(true);
                        setActive12(false);
                      }}
                    >
                      <span>
                        <MdOutlineRealEstateAgent />
                        Social Media Links
                      </span>
                    </li>
                  </Link>
                  <Link to="/contactdetails">
                    <li
                      className={`li-remove ${active11 ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(false);
                        setActive5(false);
                        setActive6(false);
                        setActive7(false);
                        setActive8(false);
                        setActive9(false);
                        setActive();
                        setActive11(false);
                        setActive12(true);
                      }}
                    >
                      <span>
                        <MdOutlineRealEstateAgent />
                        Contact Details
                      </span>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="col-sm-10">
              <div
                className=""
                style={{ overflow: "hidden", overflowX: "scroll" }}
              >
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminHeader;
