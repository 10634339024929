import React, { useEffect, useState } from "react";
import "../styles/Footer.css";
import Modal from "./Modal";
import { FaArrowRightLong, FaYoutube } from "react-icons/fa6";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import axios from "axios";
const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  const [NewData, setNewData] = useState([]);
  const GetSocialMedia = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getsocialmedialink`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetSocialMedia();
  }, []);
  const iconMap = {
    Facebook: FaFacebookSquare,
    Instagram: FaInstagramSquare,
    Twitter: FaTwitterSquare,
    YouTube: FaYoutube,
  };
  console.log("NewData",NewData);
  
  return (
    <div>
      <div className="footer ">
        <div className="footer-container container">
          <div className="footer-column">
            <h3> About Us</h3>
            <a href="/about" style={{ color: "white", textDecoration: "none" }}>
              <p>
                The First General Body meeting of the Karnataka State IT/ITeS
                Employees’ Union (KITU) had convened at YWCA Hall, Koramangala,
                Bangalore, on 20/08/2017. The union received the registration
                certificate from Labour Department on 7/10/2017.
              </p>
            </a>
            {/* <div className="mt-3">
              <a href="/about" style={{color:"white"}}>
                <h3>Follow us</h3>
              </a>
            </div> */}
          </div>
          <div className="footer-column">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <a href="/">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Home{" "}
                </a>
              </li>
              <li>
                <a href="/about">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  About
                </a>
              </li>
              <li>
                <a href="/event">
                  {" "}
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Action
                </a>
              </li>
              <li>
                <a href="/communityoutreach">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Community Outreach
                </a>
              </li>
              <li>
                <a href="/studyandresearchcenter">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Study and Research Center
                </a>
              </li>
              <li>
                <a href="/gallery">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Gallery
                </a>
              </li>
              <li>
                <a href="/contact">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-column">
            <h3>Social Media Links</h3>
            {/* <div className="social-icons">
              {NewData?.filter((ele)=>ele.media ==="Facebook" )?.map((item)=>{
                return(
                  <a
                  href={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaFacebookSquare />
                  </i>
                </a>
                )
              })}
              {NewData?.filter((ele)=>ele.media ==="Instagram" )?.map((item)=>{
                return(
                  <a
                  href={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaInstagramSquare />
                  </i>
                </a>
                )
              })}
              {NewData?.filter((ele)=>ele.media ==="Twitter" )?.map((item)=>{
                return(
                  <a
                  href={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaTwitterSquare />
                  </i>
                </a>
                )
              })}
              {NewData?.filter((ele)=>ele.media ==="YouTube" )?.map((item)=>{
                return(
                  <a
                  href={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaYoutube />
                  </i>
                </a>
                )
              })}
             
            </div> */}

  <div className="social-icons">
      {NewData?.map((item) => {
        const IconComponent = iconMap[item.media];
        return (
          IconComponent && (
            <a
              key={item?.link}
              href={item?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>
                <IconComponent />
              </i>
            </a>
          )
        );
      })}
    </div>
    <a href="/termsandconditions" className="tandc"><div className="ps-3 pt-2">Terms and Conditions</div></a>
    <a href="/privacypolicy" className="privacy"><div className="ps-3 pt-2">Privacy Policy</div></a>
    </div>

          <div className="footer-column">
            <h3>KITU </h3>
            <h3>Karnataka State IT/ITeS Employees Union</h3>
            <h3>Reg. ALC-Banglore-04/TUA/REGN/CR-17/2017-18</h3>
            <div></div>
            <div className="d-flex gap-3 align-items-center">
              <div>
                <button
                  onClick={() => setShowModal(true)}
                  className="footer-joinus"
                >
                  Join Us
                </button>
                <Modal show={showModal} onClose={() => setShowModal(false)} />
              </div>
              {/* <div>
                {" "}
                <a href="">
                  <button className="footer-donate">Donate</button>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
